@import "src/assets/styles/colors";

.ContextMenu{
  position: absolute;
  z-index: 1000;
  @media(max-width: 480px){
    left: inherit!important;
    right: 0;
  }
  .options-list{
    list-style: none;
    padding: 0;
    .option-li{
      color: white;
      background: $red;
      padding: 5px 15px;
      border-radius: 20px;
      min-width: 90px;
      margin-bottom: 5px;
      cursor: pointer;
    }
  }
}
