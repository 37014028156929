@import '../../assets/styles/colors.scss';
.DataTable {
    background:$card-background-color;
    border-radius: 9px;
    padding: 20px;
    overflow: auto;

    @media(max-width:980px){
        box-shadow: inset -50px 0 21px -21px rgba(0,0,0, 0.2);
    }
    table {
        width: 100%;
        th {
            font-size: calc(1rem + 1vw / 3);
        }
        tr {
            border-bottom: 2px solid $input-border-bottom-color;
        }
        tr:last-child {
            border: none;
        }
        td {
            padding: 15px 0;
        }
        @media(max-width: 980px){
            width: 1000px;
        }
    }
}