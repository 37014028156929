@import '../../../assets/styles/measures.scss';
@import '../../../assets/styles/colors.scss';

.Recommendations{
    max-width: $patient-max-width;
    margin: auto;
    box-shadow: 0 0 30px -10px black;
    min-height: 100vh;
    background-color: white;
    .TopBar {
        a{
            display: none;
        }
        .title {
            text-align: center;
        }
    }
    section {
        padding: 30px;
        h5 {
            font-family: Poppins-semiBold;
        }
        ul {
            margin: 0;
            list-style: none;
            padding:0;
            li {
                align-items: center;
                display: flex;
                font-size: calc(1rem + 1vw / 6);
                margin: 20px auto;
                svg {
                    color: $app-green2;
                    margin-right: 15px;
                }
            }
        }
    }
}  