@import '../../assets/styles/colors.scss';
.Sorter {
    align-items: center;
    border: 2px solid $red;
    border-radius: 10px;
    display: flex;
    margin-left: 5px;
    flex-wrap: wrap;
    padding: 6px;
    
    label, select {
        margin: 0 5px;
    }
    select {
        background: rgba(0,0,0,0);
        border: none;
    }
}