@import '../../assets/styles/colors';
@import '../../assets/styles/measures';

.MedicineList{
    background-color: white;
    padding: 5px;
    border-radius: 10px;
    
    .addElement--type__input {
        border: none;
        border-bottom: 1px solid gray;
        display: inline-block;
        margin: 10px auto;
        padding: 2px 15px;
        width: 90%;
        outline: none;
        &:focus {
            border-bottom: 1px solid $app-green1;
        }
    }
    .addElement--type__controls {
        display: flex;
        justify-content: flex-end;
        padding: 15px;
    }
    .addElement--type__button {
        border: none;
        border-radius: 6px;
        padding: 4px 14px;    
    }
    .add {
        background: $button-primary-color;
        color: white; 
    }
    .cancel {
        background: lighten($light-red, 10%);
        color: $red;
        margin-right: 12px;
    }
}

