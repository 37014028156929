@import '../../assets/styles/colors';
@import '../../assets/styles/measures';

.SearchBar{
  position: relative;
  border-radius: 10px;
  border:2px solid $red;
  color: $font-black;
  padding: 5px 5px 5px 40px;
  margin: 20px 0;

  .Search-input{
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }
  svg{
    width: 20px;
  }
  .search-icon{
    position: absolute;
    transform: translate(0,-50%);
    top: 50%;
    left: 10px;
  }
}