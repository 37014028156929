@import 'src/assets/styles/measures';

.ReportsScreen{

    max-width: $patient-max-width;
    margin: auto;

    .report-title{
        font-size: 30px;
    }
    .graph-info{
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .reportTopBar {
        h3 { display: none ;}
    }
}
