@import '../../assets/styles/colors';
@import '../../assets/styles/measures';

.OptionDots{
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: $red;
  border-radius: 100px;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
  &:before,&:after{
    top: 0;
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    background-color: $red;
    border-radius: 100px;
  }
  &:after{
    left: -10px;
  }
  &:before{
    right: -10px;
  }
}