#toast-container{
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  color: white;


  .toast {
    width: 450px;
    max-width: 90%;
    margin: 10px;
    padding: 15px 30px;
    border-radius: 100px;
    position: relative;
    z-index: 200;
    opacity: 1;
    &.toast-error {
      background-color: #fe4237;
    }
    &.toast-success {
      background-color: #3ed387;
    }
    &.toast-warning {
      background-color: #fe9c2e;
    }
    &.toast-info {
      background-color: #61a7ff;
    }
    .toast-title{
      font-weight: bold;
      font-size: 1.1em;
    }
  }

}
