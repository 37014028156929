@import '../../assets/styles/colors';
$font-bold:  Poppins-semiBold, sans-serif;
$font-light: Poppins-light, sans-serif;


.TopBar{
  background-color: $red;
  background: linear-gradient(90deg, $light-red -100%, $red 100%);
  padding: 20px calc(12px + 1vw / 2);
  position: sticky;
  width: 100%;
  z-index: 300;
  top: 0;
  &.green{
    background-color: $app-green;
    background: linear-gradient(90deg, $light-green -100%, $app-green 100%);
  }
  .mobile-content {
    display: flex;
    justify-content: space-between;
  }
  .Logout-Custom-Button {
    background: rgba(0,0,0,0);
    border: none;
    color: white;
    font-size: calc(1rem + 1vw / 5);
  }
  .primaryFont {
    font-size: calc(1rem + 1vw / 3);
    color: white;
    text-align: center;
    margin: 0;
    
    @media(min-width: 980px){
      text-align: center;
    }
  }
  .title{
    @extend .primaryFont;
    font-family: $font-bold;
  }
  .appTitle, .appTitleThin {
    width: auto;
    @media(max-width: 480px){
      width: 100px;
    }
  }
  .appTitle {
    @extend .primaryFont;
    font-family: $font-bold;
  }
  .appTitleThin{
    @extend .primaryFont;
    font-family: $font-light;
  }
  svg {
    & > *{
      stroke: white;
    }
  }
  .backBtn{
    position: absolute;
    transform: translate(0,-50%);
    top: 50%;
    background-color: transparent;
    border: none;
  }
}