@import '../../../assets/styles/colors.scss';

.MedicineFilter {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3%;

    button {
        background-color: lighten($app-green1, 40%);
        border: none;
        border-radius: 6px;
        color: $app-green;
        max-width: 130px;
        outline: none;
        padding: 6px;
        width: 100%;
        transition: .2s ease-in-out;

        &.active{
          background: $app-green;
          color: white;
        }
    }
}