@import '../../../../../assets/styles/colors.scss';



.MigraineRecordCalendar {
/*    background-color: #EAE8E8;*/
    width: 100%;
    border-radius: 20px;
    margin-top: 20px;
    @media (max-width:480px){
      padding:0;
      margin: 0 auto;
    }
    & p{
      text-align: center;
    }
  .react-calendar{
    position: relative;
    &.loading{
      &:after{
        content: 'Cargando...';
        line-height: 15;
        text-align: center;
        font-size: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 2;
      }
    }
  }
  .migraine-records-container{
    background-color: $app-green;
    padding: 50px 30px 30px 30px;
    color: white;
    margin-top: -20px;
    max-height: 300px;
    overflow-y: auto;
    border-radius: 0 0 20px 20px ;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(255,255,255,.5);
      border-radius: 100px;
    }

    span{
      color: white;
      display: block;
      font-size: .9rem;
      color: $light-gray;
    }
    .episode{
      margin-bottom: 15px;
      padding-bottom: 4px;
      border-bottom: 1px solid white;
      cursor: pointer;
    }
    .ep-title{
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
}

.react-calendar {
  width: 100% !important;
  background-color: #EAE8E8!important;
  border: none !important;
  padding: 20px;
  border-radius: 20px;
  position: relative;
  z-index: 2;
  .dayWithEpisode {
    position: relative;

    &::after {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 100px;
      background-color: $light-red;
      position: absolute;
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.react-calendar__month-view__weekdays__weekday{
  & abbr{
    text-decoration: none !important;
    font-family: 'Poppins-semiBold',sans-serif;
  }
}
.react-calendar__navigation__label{
  font-family: 'Poppins-semiBold',sans-serif !important;
  text-transform: capitalize;
}
.react-calendar__tile{
  font-family: 'Poppins-light',sans-serif !important;
}
.react-calendar__tile--active {
  background: $light-green !important;
  border-radius: 8px;
  color: white;
}

.react-calendar__navigation__next-button{
  position: relative !important;
  &::after{
    content: '';
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    position: absolute;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #DD1809;
  }
}
.react-calendar__navigation__prev-button{
  position: relative !important;
  &::after{
    content: '';
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    position: absolute;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #DD1809;
  }
}
.react-calendar__navigation__next2-button{
  position: relative !important;
  &::after{
    content: '';
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #DD1809;
  }
}
.react-calendar__navigation__prev2-button{
  position: relative;
  &::after{
    content: '';
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #DD1809;
  }
}
