@import '../../assets/styles/colors.scss';

.PainLocationPicker{
  .head-wrap{
    margin: 20px auto;
    width: 190px;
    position: relative;
    *{
      pointer-events: none;
    }
    .face-wire{
      width: 100%;
      margin: auto 20px;
    }
    .location-image{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .location-labels {
    display: flex;
    flex-wrap: wrap;
  }
  .location-label {
    align-items: center;
    background-color: $app-green2;
    border-radius: 6px;
    color: white;
    display: flex;
    margin: 2px 10px;
    font-size: 13px;
    text-align: left;
    padding: 6px 15px;

    .remove-location {
      background-color: rgba(0,0,0,0);
      border: none;
      outline: none;
      margin-left: 5px;
      img {
        width: 15px;
      }
    }
  }
  .pain-low {
    opacity: 0.2;
    filter: contrast(200%);
  }
  .pain-medium {
    opacity: 0.5;
    filter: contrast(200%);
  }
  .pain-high {
    filter: contrast(200%);
  }
}
