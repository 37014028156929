@import '../../assets/styles/colors.scss';

.Modal {
    backdrop-filter: blur(20px);
    bottom:0;
    height: 100%;
    left: 0;
    position: fixed;
    top:0;
    right: 0;
    width: 100%;
    z-index: 900;
    align-items: center;
    display: flex;
    justify-content: center;

    .exit-zone {
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: -1;
    }

    .modal-container {
        background-color: $card-background-color;
        border-radius: 30px;
        width: 100%;
        max-width: 600px;
        height: 100%;
        max-height: 600px;
        overflow: auto;

        .modal-header {
            background: linear-gradient(90deg, $light-red -100%, $red 100%);
            color: white;
            padding: 12.5px 25px;
            width: 100%;
            h3 {
                font-size: 20px;
                margin: auto;
            }
            img {
                cursor: pointer;
            }
        }
        section {
            text-align: center;
            .modal-description {
                margin: 20px auto;
                padding: 0 20px;
            }
        }
    }
}
