@import '../../../assets/styles/colors';
@import '../../../assets/styles/measures';

.MainScreen{
  position: relative;
  max-width: $patient-max-width;
  margin: auto;
  box-shadow: 0 0 30px -10px black;
  min-height: 100vh;
  background-color: white;

  .patientTopBar {
    display: flex;
    justify-content: space-between;
    margin: auto 0;
    width: 100%;

    h1{
      margin-left: -15px; 
      text-align: center;
    }
    .Logout-Custom-Button {
      color: white;
      svg {
        @media(max-width: 480px){
          display: none;
        }
        margin-left: 5px;
      }
      margin-left: 5px;
      @media(max-width: 480px){
        margin-left: -40px;
        max-width: calc(480px / 3);
        width: 35%;
        text-align: right;
      }
    }
    .Logout-Button{
      color: white;
      position: absolute;
      right: 1vw;
      top: 0.5vw;
      font-size: 25px;
    }
  }
  .content{
    /*width: calc( 100% - 120px );*/
    min-width: 80%;
    margin: auto;
  }
  .face-container{
    text-align: center;
    margin-top: 1rem;
  }
  .home-message{
    font-size: 20px;
    text-align: center;
    margin: 30px 0;
  }
  .day-streak{
    text-align: center;
    .streak-count{
      position: relative;
      z-index: 2;
      display: inline-block;
      $circeSize:84px;
      width: $circeSize;
      height: $circeSize;
      border: 1px solid $app-black;
      border-radius: 100%;
      .number{
        font-size: 33px;
        line-height: $circeSize;
        vertical-align: middle;
        position: relative;
      }
    }

    .streak-message{
      margin: 24px 0;
      font-size: 19px;
      color: $app-green;
    }

  }
  .new-episode-btn{
    display: inline-block;
    width: 100%;
    margin: 30px 0 15px;
  }
  .nav-buttons{
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin-top: 50px;

    @media(max-width: 480px){
      flex-wrap: wrap;
      margin:auto;
      max-width: 220px;
      width: 100%;
      .nav-btn {
        margin-top: 30px;
        max-width: 100px;
        width: 100%;
      }
      .large-label {
        margin-left: -20px;
      }
    }

    .nav-btn{
      min-width: 70px;
      transition: transform 80ms;
      svg {
        path{
          fill: $red;
        }
      }
      &:active{
        transform: scale(0.8);
      }
      &:visited, &:focus, &:hover{
        text-decoration: none;
        color: $red;
        &.green{
          color: $app-green;
        }
      }
    }

    .icon{
      border: 2px solid $red;
      border-radius: 14px;
      display: inline-block;
      width: 40px;
      height: 40px;
      &.green{
        border-color: $app-green;
        color: $app-green;
      }
      svg{
        vertical-align: middle;
        width: 20px;
        height: 36px;

      }
    }
    .label{
      color: $red;
      font-family: Poppins-light, sans-serif;
      &.green{
        color: $app-green;
      }
    }
  }
  .streak-count-lightRed{
    position: relative;
    $circeSize:84px;
    width: $circeSize;
    height: $circeSize;
    margin: 0 auto;
  }
  .streak-count-lightRed:after{
    content: '';
    position: absolute;
    top: 10%;
    right: -15%;
    border-radius: 100%;
    background-color: $light-red;
    $circeSize:84px;
    width: $circeSize;
    height: $circeSize;
  }
  .version{
    text-align: center;
    color: #909090;
    &.newer{
      color: red;
      cursor: pointer;
    }
  }
}
