@import '../../../assets/styles/colors.scss';

$disabled-color: darken($light-gray, 35%);
//Rules
.Sponsors {
  font-size: calc(1rem + 1vw / 6);

  header {
    margin-bottom: 30px;
  }

  .logout-white {
    background: rgba(0, 0, 0, 0);
    border: none;
    color: white;
  }

  .sponsor-content, .topBar {
    margin: auto;
    width: calc(100vw - 8%);
  }

  .create-button {
    background-color: $button-primary-color;
    border-radius: 9px;
    border: none;
    color: white;
    padding: 8px;
    width: 100%;

    img {
      margin-right: 12px;
      width: 12px;
    }
  }

  .searchBar {
    max-width: 50%;
    width: 100%;
    @media(max-width: 768px) {
      max-width: 50%;
    }
  }

  .sponsor-content {
    .modal-description {
      font-family: Poppins-light;
      font-size: 17px;
      margin: 20px auto 40px auto;
      width: 70%;
    }

    .th-name {
      color: $app-green;
    }

    .th-contactName {
      color: $red;
    }

    @mixin lab-status {
      border-radius: 100%;
      display: inline-block;
      height: 10px;
      width: 10px;
      margin-right: 9px;
    }

    .lab-active {
      @include lab-status;
      background: $app-green;
    }

    .lab-disabled {
      @include lab-status;
      background-color: $disabled-color;
    }

    td.disabled {
      color: $disabled-color;
    }


    .actions {
      .remove, .edit {
        border: none;
        margin-right: 12px;
      }

      .remove {
        background-color: rgba(0, 0, 0, 0);
      }

      .edit {
        background-color: $button-secondary-color;
        border-radius: 9px;
        color: white;
        padding: 6px 20px;
        transition: .2s ease-in-out;

        &:hover {
          background-color: darken($button-secondary-color, 5%);
        }
      }
    }

    .active {
      transition: .3s ease-in-out;
    }

    .muted {
      background: rgba(0, 0, 0, 0.2);
      color: darken(gray, 10%);
    }

    @mixin account-buttons {
      opacity: 0.7;
      margin-left: 5px;
      transition: .2s ease-in-out;
      &:hover {
        opacity: 1;
      }
    }

    .active-account {
      @include account-buttons;
    }

    .mute-account {
      @include account-buttons;
      background-color: lighten($light-red, 9%);
      color: $red;
    }

    .accept-error {
      background-color: $button-primary-color;
      border: none;
      border-radius: 3px;
      color: white;
      margin: 10px auto;
      padding: 9px 14px;
    }
  }

  .send-welcome {
    small {
      display: block;
      font-size: 10px;
    }
  }
}