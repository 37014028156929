@import '../../../../assets/styles/colors.scss';
.DoctorProfile {
    .version{
        position: absolute;
        z-index: 301;
        color: rgba(255, 255, 255, 0.62);
        right: 2px;
        font-size: 10px;
    }
    .TopBar {
        width: 100%;
        border-radius: 0;
        padding: 20px;
        .title {
            @media(min-width: 650px){
                left: 25px;
            }
            position: relative;
        }
        .custom-doctor-topbar-container{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    .management-dropdown button , .profile-dropdown button {
        @media(max-width: 680px){
            margin: 0;
            margin-top: 15px;
        }
    }

    .profile-dropdown {
        position: relative;
        button[type="button"] {
            align-items: center;
            background: rgba(255, 255, 255, 0.3);
            border: none;
            border-radius: 3px;
            color: white;
            display: flex;
            justify-content: center;
            padding: 6px calc(14px + 1vw);
            max-width: 240px;
            width: 100%;
            svg {
                margin-left: 7px;
            }

            h5 {
                font-size: calc(1rem + 1vw / 6);
                margin: 0;
            }
        }
        .dropdown-wrapper {
            background-color: white;
            box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
            border-radius: 0 0 9px 9px;
            display: none;
            right: 0;
            font-size: calc(1rem + 1vw / 6);
            position: absolute;
            width: 100%;

            nav {
                padding: 15px;
                svg {
                    margin-left: 5px;
                }
            }
        }
        &:hover {
            .dropdown-wrapper {
                display: flex;
            }
        }
    }

    .management-dropdown {
        margin-right: 10px;
        .management-button {
            color: white;
        }
        button{
            border: none;
            border-radius: 3px;
            color: black;
            transition: .2s ease-in-out;
            padding: 3px 17px;
            font-size: calc(1rem + 1vw / 6);
            svg {
                margin-left: 5px;
            }
            max-width: 140px;
            width: 100%;
        }
    }
    .management-dropdown {
        z-index: 99;

        .management-dropdown-wrapper {
            background: white;
            border-radius: 9px;
            box-shadow: 0 6px 12px rgba(0,0,0, 0.1);
            display: none;
            flex-wrap: wrap;
            position: absolute;
            padding: 24px;
            width: 200px;
            @media(max-width: 480px){
                left: 0;
            }

            ul {
                padding: 0;
                margin: 0;
                padding-left: 20px;
                li{
                    a{
                        width: 100%;
                        button {
                            background: none;
                            border-radius: 0;
                            font-size: 15px;
                            margin: auto;
                            text-align: left;
                            padding: 8px 12px;
                            padding-left: 0;
                            &:hover {
                                color: $app-green;
                            }
                        }
                    }
                    &:hover {
                        color: $app-green;
                    }
                }
            }
        }
        &:hover{
            .management-dropdown-wrapper{
                display: flex;
            }
        }
    }
}