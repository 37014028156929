
$red: #DD1707;
$gray: #797575;
$light-red: #f1a19b;
$light-gray: #F2F0F0;
$light-yellow: #fddeb9;
$dark-gray: #c2c0c0;
$logo-white: #F7F7F7;
$opacity-white: rgba(255,255,255,0.3);

$app-black: #191919;
$font-black: #080808;
$circle-black:#030303;

$app-green: #0A801A;
$light-green: #48A054;

$app-green1: rgba(72, 160, 84, 1);
$app-green2: rgba(10, 128, 26, 0.75);
$app-green3: rgba(131, 191, 139, 1);
$app-green4: rgba(10, 128, 26, 0.51);


//Background Colors
$background-light: #FFFFFF;
$card-background-color: #F2F0F0;
$red: #DD1707;
$light-red: #f1a19b;

//Color States
$muted: #ABADAB;
$active: #0A801A;

//Elements Colors
$input-icon-color: #DD1707;
$input-border-color: #DD1707;
$input-border-bottom-color: #9B9A9A;
$button-primary-color: #191919;
$button-secondary-color: #0A801A;

//Font Colors
$font-color-red: #DD1707;
$font-color-dark: #191919;
$font-color-light: #FFFFFF;
$font-color-green: #0A801A;

//Font weight properties
$font-weight-bold: 600;
$font-weight-light: lighter;