@import 'src/assets/styles/measures';

.EpisodeList{
  max-width: $patient-max-width;
  margin: auto;

  h6.date-content:first-child {
   color: red;
   opacity: 1;
  }
  h6.date-content {
    font-family: Poppins-semiBold;
    margin: 0;
    padding: 50px 0 20px 0;
    opacity: 0.6;
  }
  .no-episodes{
    padding-top: 20px;

    h5{
      font-size: 20px;
      padding-bottom: 10px;
    }
    button{
      width: 100%;
    }
  }
  .historyTopBar {
    h3 { display: none ;}
  }
}