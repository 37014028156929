@import '../../assets/styles/colors';

.PainFace {
  position: relative;
    z-index: 1;
  .face-wrapper {
    width: 34px;
    height: 34px;
    display: inline-block;

    &.red{
      .small-face{
        width: 34px;
        height: 42px;
      }
      &:after{
        background-color: #fc6203;
        top: 30%;
        left: 23%;
        width: 80%;
        height: 80%;
      }
    }

    &.green:after{
      background-color: $light-green;
    }
  }

  .small-face {
    width: 34px;
    height: 34px;
  }

}

