@import 'src/assets/styles/colors';

.IntensitySlider{
  position: relative;
  .handle{
    display: inline-block;
    .HeadIcon{
      height: 50px;
    }
    .small-circle {
      position: absolute;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background-color: black;
      bottom: -24px;
      left: 10px;
    }
  }
  .guide-line{
    margin-top: 16px;
    background-color: black;
    height: 2px;
  }
  .numbers-wrapper{
    margin: 15px 0 0 0;
    font-size: 0.9em;
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
  }
  .level-texts{
    display: flex;
    justify-content: space-between;
  }
  .step-label{
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    text-align: center;
    vertical-align: middle;
    border-radius: 1em;

    &.active{
      background-color: $light-red;
    }
  }
}