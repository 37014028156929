@import 'src/assets/styles/colors';

.BlockPicker{
  text-align: left;

  .block-picker-col{
    padding: 5px;
  }

  .selectable-block{
    background-color: $app-green1;
    border-radius: 10px;
    color: white;
    padding: 15px 5px 5px 5px;
    position: relative;
    margin: 4px 0;
    .block-title {
      margin: 0;
    }
    .selection-mark{
      position: absolute;
      right: 0;
      top: 0;
      background: rgba(255,255,255,0.2);
      border-radius: 0 0 0 5px;
      padding: 5px;
      line-height: 15px;
      .icon{
        width: 15px;
        height: 15px;
      }
    }
    &:active{
      transform: scale(0.95);
    }


    .icon {
      padding-right: 4px;
    }
    button.button {
      border: none;
      background-color: rgba(0,0,0,0);
      border-radius: 3px;
      font-size: small;
      color: white;
    }
    form{
      input {
        background-color: rgba(0,0,0, 0);
        border: none;
        border-bottom: 1px solid rgba(255,255,255,0.6);
        color: white;
        outline: none;
        width: 100%;
      }
      ::-webkit-input-placeholder {
        color: rgba(255,255,255,0.8);
      }
      .button {
        padding: 8px;
      }
      .button--action__primary {
        border: none;
        border-radius: 6px;
        background-color: $app-black;
        color: white;
        margin-top: 10px;
        margin-right: 4px;
      }

      .button--action__secondary{
        border: none;
        border-radius : 6px;
        background-color: $dark-gray;
        color: black;
        margin-top: 10px;
        margin-right: 4px;
      }
    }
  }
  .green-high {
    background: lighten($app-green, 5%);
  }
  .green-medium {
    background: lighten($app-green, 2.5%);
  }
  .green-low {
    background: darken($app-green, 0.5%);
  }
}