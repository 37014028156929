@import '../../../assets/styles/colors';
@import '../../../assets/styles/measures';

.NewEpisode {

  max-width: $patient-max-width;
  margin: auto;
  box-shadow: 0 0 30px -10px black;
  min-height: 100vh;
  background-color: white;
  font-size: calc(1rem + 1vw / 3.5);

  .duration{
    text-align: center;
    margin-bottom: 10px;
    width: 100%;
    &>div{
      display: inline-block;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    label{
      margin: 0;
      line-height: 1;
    }
    input{
      width: 100%;
      background-color: transparent;
      border: none;
      color: white;
      padding:8px;
      padding-bottom: 0;
      text-align: center;
      height: 50%;
      margin-bottom: 3px;
      outline: none;
      
      &::-webkit-input-placeholder { /* Edge */
        color: white;
        font-size: 10px;
      }

      &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: white;
      }

      &::placeholder {
        color: white;
      }
    }
    .hours, .minutes {
      background-color: #48A054;
      max-width: 200px;
      width: 100%;
      border-radius: 10px;
      line-height: 1.2;
      
      @media( max-width: 499px ) {
        max-width: 100px;
        width: 100%;
      }
    }
    .dots{
      width: 5%;
      text-align: center;
      vertical-align: super;
    }
    
    /*.hours{
      //width: 20%;
      //min-width: 100px;
      border-radius: 10px;
      line-height: 1.2;

    }*/
    /*.minutes{
      //width: 20%;
      //min-width: 100px;
      //background-color: #48A054;
    }*/
  }

  .top-space {
    position: relative;
    color: white;
    background-color: $red;
    padding: 0 $app-padding 1px;
    border-radius: 15px;

    &.green {
      background-color: $app-green;

      .hour-picker {
        input {
          background-color: $light-green;
        }
      }
    }

    .title {
      font-size: 18px;
      padding: 22px;
      margin: 0;
      text-align: center;
      font-family: Poppins-semiBold, sans-serif;
    }

    .date-string {
      margin: 12px 0 18px;
    }

    .DateCarousel {
      margin-bottom: 22px;
    }

    .hour-picker {
      display: block;
      margin-bottom: 20px;

      input {
        height: 40px;
        background-color: $light-red;
        border: none;
        border-radius: 10px;
        text-align: center;
        color: white;
        font-size: 16px;
        font-family: Poppins-light, sans-serif;
      }
    }
  }

  .second-space {
    //background-color: $light-gray;
    border-radius: 0 0 22px 22px;
    padding-bottom: 10px;

    .intensity-slider {
      padding: 30px $app-padding 1px;
      margin: 0px 0 10px 0;
      background-color: $light-gray;
    }

    .extra-block {
      text-align: center;
      padding: 10px $app-padding 10px;
      max-width: 480px;
      margin:auto;
    }

    .extra-btn {
      color: $app-green;
      background-color: rgba(0,0,0,0);
      min-width: 80%;
      display: flex;
      align-items: center;
      text-align: left;

      .plusIcon {
        color: $app-green;
        background: lighten($light-green, 30%);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        height: 27px;
        width: 27px;
        margin-right: 30px;
        svg {
          margin-top: 0px;
        }
      }
      svg {
        float: left;
        margin-top: 5px;
      }

      .icon {
        width: 15px;
        height: 15px;
      }
    }
  }

  .save-wrap {
    text-align: center;
    padding: 40px;

    .Button {
      min-width: 80%;
    }
  }

  .Splash {
    text-align: center;
    padding: 30px;

    .logo-title{
      margin-top: 15px;
    }

    .save-message {
      font-size: 1.4em;
    }
  }

  &.saving {
    overflow: hidden;
    height: 100vh;
  }

  .backBtn-datePicker {
    position: absolute;
    transform: translate(0, -50%);
    top: 32px;
    background-color: transparent;
    border: none;

    svg {
      & > * {
        stroke: white;
      }
    }
  }
  .extra-blocks, .patient-comments-section {
    background-color: #F2F0F0;
    padding: 20px 26px 1px;
    padding-bottom: 20px;
  }
  
  .patient-comments-section {
    border-radius: 0 0 40px 40px;  
    label {
      text-align: left;
      margin-top: 14px;
      color: rgba(0,0,0, 0.8);
      textarea {
        background: none;
        border: none;
        border-bottom: 1px solid rgba(0,0,0, 0.4);
        margin-top: 10px;
        outline: none;
        height: 30px;
        width: 100%;
      }
    }
  }

}
