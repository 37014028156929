@import '../../../../assets/styles/colors.scss';
.MultiSelect {
    position: relative;
    margin-bottom: 20px;

    &:hover {
        ul.options-wrapper {
            display: block;
        }
    }

    .select-container {
        margin-bottom: 5px;
        div {
            border: 2px solid $red;
            border-radius: 10px;
            color: rgba(0,0,0, 0.6);
            cursor: pointer;
            display: inline-flex;
            padding: 7px;
            padding-right: 19px;
            overflow-x: auto;
            max-width: 340px;
            width: 100%;
            svg {
                margin-left: 9px;
            }
            .selected-block {
                align-items: center;
                background-color: $app-green;
                border-radius: 3px;
                box-shadow: 0 4px 12px rgba(0,0,0, 0.1);
                color: white;
                display: inline-flex;
                margin: 5px;
                padding: 9px;
                
                img {
                    cursor: pointer;
                    margin-left: 10px;
                    width: 18px;
                }
            }
        }
    }
 
    ul.options-wrapper {
        background-color: white;
        box-shadow: 0 5px 9px rgba(0,0,0, 0.1);
        border-radius: 0 0 9px 9px;
        display: none;
        height: 300px;
        margin: 0;
        list-style: none;
        padding: 0;
        position: absolute;
        top: 100%;
        overflow-y : scroll;
        padding: 12px;
        width: 100%;
        z-index: 999999;
        li {
            margin: 10px auto;
            button {
                background-color: rgba(0,0,0, 0.1);
                color: black;
                width: 100%;
                &:disabled {
                   display: none
                }
            }
        }
    }
}