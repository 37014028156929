@import "src/assets/styles/colors";

.DotsButton{
  padding: 4px;
  background: none;
  border: none;

    span{
      display: inline-block;
      width: 0.4em;
      height: 0.4em;
      border-radius: 1em;
      background-color: $red;
      margin: 2px;
    }
}
