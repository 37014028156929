@import '../../assets/styles/colors';


.ResetPassword{
  width: 50%;
  min-width: 300px;
  margin: auto;
  margin-top: 200px;
  text-align: center;

  input{
    color: black;
    border: 1px solid black;
    &::placeholder{
      color: $app-green;
    }
  }
}