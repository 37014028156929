@import '../../../../assets/styles/colors.scss';

$cardPadding: 9px 14px;
$boxShadow:  0 2px 9px rgba(0,0,0, 0.1);


.MiscManagement {
    background-color: white;
    .miscSearchBar {
        background-color: white;
    }
    .manageElements, .miscSearchBar {
        top: 60px;
        position: sticky;
    }

    @mixin form-styles( $background, $width, $marginUpDown, $borderRadius) {
        background-color: $background;
        border-radius: $borderRadius;
        padding: 21px;
        margin: $marginUpDown auto;
        width: $width;

        fieldset {
            .mobile-exit-form {
                background: #00000000;
                filter: invert(1);
                position: absolute;
                right: 2%;
                top: 0;
                img {
                    width: 21px;
                }
            }
            label {
                display: block;
                padding: 1px 0 ;
                text-align: left;
            }
            input {
                background: rgba(0,0,0,0);
                border: 2px solid $red;
                border-radius: 10px;
                display: block; 
                margin: 9px auto;
                padding: 7px;
                outline: none;
                width: 100%;
            }
            button {
                background: $button-secondary-color;
                border: none;
                border-radius: 10px;
                color: white;
                cursor: pointer;
                padding: 10px 5%;
            }
        }
        
        .error {
            display: block;
        }
        button.submit, button.exitEditMode {
            border-radius: 4px;
            margin-right: 5px;
            box-shadow: $boxShadow;    
        }
        button.exitEditMode {
            background: lighten($light-red, 9%);
            color: $red;
        }
        button.submit {
            background-color: $app-black;
            img {
                width: 12pt;
                margin-right: 10px;
            }
        }

        @media(max-width: 980px){
            max-width: 100%;
            width: 100%;
        }
    }
    .manageElements {
        @include form-styles($light-gray, 80%, 30px, 6);
        @media( max-width: 480px ){
            position: relative;
            top: 0;
        }
    }
    
    .data-cards {
        .data-card {
            align-items: stretch;
            border-radius: 4px;
            box-shadow: $boxShadow;
            color: white;
            display: flex;
            justify-content: space-between;
            margin: 10px auto;
            overflow: hidden;
            p{
                background-color: $app-green;
                margin: 0;
                width: 100%;
                padding: $cardPadding;
            }
            button.actions {
                border: none;
                padding: $cardPadding;
                max-width: 48px;
                width: 100%;
            }
        }  
    }
}