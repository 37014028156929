@font-face {
  font-family:'Poppins-medium';
  src:url('./Poppins/Poppins-Medium.ttf');
}
@font-face {
  font-family:'Poppins-light';
  src:url('./Poppins/Poppins-Light.ttf');
}
@font-face {
  font-family:'Poppins-semiBold';
  src:url('./Poppins/Poppins-SemiBold.ttf');
}
