@import '../../../assets/styles/colors.scss';

.MedicineListPage{
    background-color: white;
    font-size: calc(1rem + 1vw / 6);

    .modal-description {
        margin: 30px auto;
    }
    .FormCreator {
        input, select {
            margin: 5px auto;
        }
        .SelectMedicinesGroup {
            background: rgba(0,0,0,0);
            border: 2px solid $red;
            border-radius: 10px;
            padding: 9px;
            width: 100%;
        }
        button {
            margin-top: 20px;
        }
    }

    header{
        .searchMedicineBar {
            max-width: 70%;
            margin-right: 20px;
            width: 100%;
        }
        .button-addMedicine {
            background-color: $button-primary-color;
            border: none;
            border-radius: 9px;
            color: white;
            max-width: 30%;
            padding: 6px;
            width: 100%;
            img {
                margin-right: 14px;
            }
        }
    }

    .MedicineCard {
        @media(min-width: 768px) {
            float: left;
            max-width: 480px;
            width: 100%;
            margin: 10px 20px;
        }
        margin: 10px auto;
    }
}