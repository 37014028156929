.PatientFilters {
    .filter {
        margin: 5px;
    }
  div{
      float: left;
  }
    .multi-select {
        --rmsc-border: white;
        --rmsc-gray: white;
        --rmsc-radius: 10px;

        .dropdown-container {
            .dropdown-heading-value {
                color: white;
            }
            background: none;
            border: 1.5px solid white;
            width: 250px;
        }
    }
}