@import '../../../assets/styles/colors';
@import '../../../assets/styles/measures';

.MedicineCard{
  background-color: $light-gray;
  border-radius: $border-radius-cards;;

  .container{
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    /* padding: $app-padding;*/
     .pills-img{
       position: relative;
       &:before{
         content: '';
         width: 30px;
         height: 30px;
         position: absolute;
         top: 10%;
         left:10%;
         background-color: $light-red;
         border-radius: 200px;
       }
     }
     .oval-pills-img{
       position: relative;
       &:before{
         content: '';
         width: 45px;
         height: 20px;
         transform: rotate(-65deg);
         position: absolute;
         top: -12%;
         right:-15%;
         background-color: $light-yellow;
         border-radius: 200px;
       }
     }
     .circle-pill{
       position: relative;
       z-index: 2;
       width: 30px;
       height: 30px;
       border:1px solid $circle-black;
       border-radius: 200px;
     }
     .oval-pill{
       width: 40px;
       height: 20px;
       transform: rotate(-65deg);
       border:1px solid $circle-black;
       border-radius: 50px;
     }
     
     .medicine-name{
       font-family: 'Poppins-semiBold', sans-serif;
       font-size: 16px;
       margin: auto;
       position: relative;
       .medicine-group{
         color: $gray;
         font-size: .9rem;
         position: absolute;
         top: 1.4rem;
         left: 50%;
         transform: translateX(-50%);
       }
     }
   
     &:not(:last-child){
       margin-bottom: 20px;
     }
  }
  .optionDots-wrapper{
    position: relative;
    float: right;
    height: 10px;
    width: 40px;
    top: 20px;
    right: 10%;

    .actions-container {
      display: flex;
      flex-direction: row;

      @mixin buttonPrototype {
        background: rgba(0,0,0,0);
        border: none;
        margin: auto 4px;
      }
      .button--type__remove {
        color: $red;
        @include buttonPrototype;
      }
      .button--type__edit {
        @include buttonPrototype;
      }
    }
  }
  .quantity-changer{
    .less, .more{
      cursor: pointer;
      width: 30px;
      height: 30px;
      line-height: 1.5;
      font-size: 20px;
      margin-left: 5px;
      margin-right: 5px;
      text-align: center;
      border-radius: 300px;
     display: inline-block;
    }
    .more{
      background-color: $app-green3;
      color: $app-green;
    }
    .less{
      background-color: $light-red;
      color: $font-color-red;
    }
  }
}
