.TriggerBar{
  display: block;
  margin-bottom: 20px;
  .icon-container{
    width: 15%;
    display: inline-block;
    vertical-align: middle;
    .icon{
      width: 33px;
      height: 33px;
      background-color: blue;
      border-radius: 100%;
      position: relative;
      svg{
        width: 18px;
        height: 18px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
      }
    }
  }
  .percentage-container{
    width: 85%;
    display: inline-block;
    vertical-align: middle;

    .trigger-name {
      width: 80%;
      display: inline-block;
      margin-bottom: 5px;

    }
    .percentage-number{
      width: 20%;
      text-align: right;
      display: inline-block;
      opacity: .5;
    }
    .percentage-bar{
      width: 100%;
      height: 6px;
      border-radius: 6px;
      position: relative;
      .percentage-bar-fill{
        border-radius: 10px;
        height: 6px;
      }
    }
  }
}