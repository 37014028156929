@import '../../../../assets/styles/colors.scss';
hr {
    margin: 50px auto;
}
.patient-diagnostic-container {
    margin-bottom: 25px;
    .diagnostic-container{
        background: $app-green;
        border-radius: 4px;
        color: white;
        display: inline-block;
        margin: 10px 5px;
        padding: 9px;
    }
}