@import '../../../assets/styles/colors.scss';


//Rules
.Laboratories {
    font-size: calc( 1rem + 1vw / 6);
    header{
        .buttons{
            .create-button, .licensesStatus, .config-button {
                display: block;
                margin-bottom: 10px;   
            }
            @media(max-width: 980px){
                margin-bottom: 30px;
                display: block !important;
            }
        }
    }
    .logout-white {
        background: rgba(0,0,0,0);
        border: none;
        color: white;
    }
    .laboratory-content, .topBar {
        margin: auto;
        width: calc(100vw - 8%);
    }
    .laboratory-content {
        .create-button {
            background-color: $button-primary-color;
            border-radius: 9px;
            border: none;
            color: white;
            padding: 8px;
            max-width: 200px;
            width: 100%;
            img {
                margin-right: 12px;
                width: 12px;
            }
        }
        .searchBar {
            max-width: 50%;
            width: 100%;
            @media(max-width: 768px){
                max-width: 50%;
            }
        }
        .licensesStatus {
            border-radius: 10px;
            background-color: $app-green;
            color: white;
            padding: 9px 14px;
            max-width: 300px;
            width: 100%;
        }
        .modal-description{
            font-family: Poppins-light;
            font-size: 17px;
            margin: 20px auto 40px auto;
            width: 70%;
        }
        .th-name{
            color: $app-green;
        }
        .th-contactName {
            color: $red;
        }
        .lab-active {
            background: $app-green;
            border-radius: 100%;
            display: inline-block;
            height: 10px;
            width: 10px;
            margin-right: 9px;
        }
        .actions {
            .remove, .edit {
                border: none;
                margin-right: 12px;
            }
            .remove {
                background-color: rgba(0,0,0,0);
            }
            .edit {
                background-color: $button-secondary-color;
                border-radius: 9px;
                color: white;
                padding: 6px 20px;
                transition: .2s ease-in-out;
                &:hover {
                    background-color: darken($button-secondary-color, 5%);
                }
            }
        }
        .active {
            transition: .3s ease-in-out;
        }
        .muted {
            background: rgba(0,0,0, 0.2);
            color: darken(gray, 10%);
        }
    }
    .config-button{
        background-color: $app-black;
        color: white;
        padding: 8px;
        width: 70px;
        text-align: center;
        border-radius: 10px;
        cursor: pointer;
    }
    .send-welcome {
        small {
            display: block;
            font-size: 10px;
        }
    }
}