@import '../../../assets/styles/colors.scss';

.PatientProfile {
  background-color: #f4f2f2;
  overflow-x: hidden;

  .ParentRow {
    margin: auto;
    max-width: 1920px;
    padding: 50px 5%;

    .LeftSide {
      padding: 0;
      display: flex;
      flex-direction: column;
      //PROFILE PHOTO
      div.profile-init-data {

        img.profile-photo, img.no-photo {
          border-radius: 100%;
          box-shadow: 0 2px 9px rgba(0, 0, 0, 0.2);
          margin-bottom: 30px;
          height: 100px;
          width: 100px;

          @media(max-width: 980px) {
            display: block;
            margin: auto;
          }
        }

        img.no-photo {
          opacity: 0.4;
        }

        .title {
          color: #0B801A;
          font-size: 22px;
          @media (max-width: 992px) {
            text-align: center;
          }
        }

        .title-name {
          color: #DD1809;
          @media (max-width: 992px) {
            text-align: center;
          }
        }

        .personalInfo {
          @media (max-width: 992px) {
            text-align: center;
          }
        }

        .personalInfo p {
          font-family: 'Poppins-light', sans-serif;
          margin: 0;
        }
      }

      //PATIENT DIAGNOSTIC
      div.patient-diagnostic-section {
        .patient-diagnostic-container {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            align-items: center;
            background: white;
            border-radius: 6px;
            box-shadow: 0 9px 12px rgba(0, 0, 0, 0.05);
            display: flex;
            justify-content: space-between;
            margin: 10px 0;
            max-width: 295px;
            padding: 9px;
            width: 100%;

            button {
              align-items: center;
              background: none;
              border: 1px solid rgba(0, 0, 0, 0.15);
              border-radius: 3px;
              display: flex;
              justify-content: center;
              height: 30px;
              width: 30px;
            }
          }
        }

        button.saveData {
          background-color: $button-primary-color;
          border: none;
          border-radius: 6px;
          color: white;
          margin: 20px 0;
          padding: 9px 14px;
        }
      }

      //PATIENT TIME RESULTS

      //PATIENT MANAGEMENT
      .patient-management {
        .patient-management-options {
          button {
            align-items: center;
            background: $app-green;
            border: none;
            border-radius: 3px;
            box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
            color: white;
            display: flex;
            justify-content: space-between;
            margin: 10px 0;
            max-width: 240px;
            padding: 9px 14px;
            width: 100%;

            img {
              margin-left: 7px;
            }
          }
        }

        .Modal {
          .modal-description {
            margin: 30px auto;
            max-width: 480px;
            width: 100%;
          }

          @mixin modalButtonPrototype($maxWidth) {
            border: none;
            border-radius: 4px;
            padding: 6px 14px;
            margin: 5px 0;
            max-width: $maxWidth;
            width: 100%;
          }

          .patient-medicineAssignment-management {
            background-color: white;
            margin: auto;
            border-radius: 21px 21px 0 0;
            box-shadow: 0 -5px 12px rgba(0, 0, 0, 0.05);
            padding: 5%;
            width: 100%;

            h6 {
              text-align: left;
            }

            ul {
              list-style: none;
              padding: 5%;
              margin: 0;
              height: 330px;
              overflow-y: scroll;

              .disabled {
                display: none;
              }

              li {
                align-items: center;
                display: flex;
                justify-content: space-between;

                .add, .remove {
                  @include modalButtonPrototype(100px);
                }

                .add {
                  background-color: $app-green;
                  color: white;
                }

                .remove {
                  background-color: $light-red;
                  color: $red;
                }
              }
            }

            .send {
              @include modalButtonPrototype(100%);
              background: $button-primary-color;
              color: white;
              padding: 12px;

              &:disabled {
                background: rgba(0, 0, 0, 0.2);
                color: rgba(0, 0, 0, 0.7);
              }
            }
          }

          .midas-and-hit6-container {
            label, input {
              display: block;
              text-align: left;
              width: 100%;
            }

            label {
              max-width: 320px;
              margin: 10px auto;
            }

            input {
              background: none;
              border: 2px solid $red;
              border-radius: 6px;
              margin: 6px 0;
              padding: 6px 10px;
            }

            button {
              background: $app-green;
              border: none;
              border-radius: 3px;
              padding: 9px;
              color: white;
              margin: 30px 0;
              transition: .2s ease-in;

              &:disabled {
                background-color: rgba(0, 0, 0, 0.2);
                color: black;
              }
            }
          }
        }
      }
    }

    .RightSide {

      .datePickers-wrapper {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 20px;

        & label {
          margin-right: 20px;
        }

        .react-date-picker__wrapper {
          border: none;
        }

        .datePicker-filter {
          border-radius: 10px;
          border: 2px solid #DD1A0F;
          padding: 5px 10px;
          background-color: rgba(255, 255, 255, .5);
          margin-left: 20px;
        }

        .react-date-picker__calendar {
          z-index: 9999;

          .react-calendar {
            box-shadow: 0 -3px 9px rgba(0, 0, 0, 0.1);
          }
        }

        @media(max-width: 480px) {
          margin-top: 50px;
        }
      }

      .graphic-background {
        max-height: 295px;
        height: 100%;
        width: 100%;
        @media(max-width: 480px) {
          max-width: none;
          height: initial;
        }
      }

      .pain-locations-section {
        .PainLocationPicker {
          .head-wrap {
            width: 158px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .widget {
    margin-bottom: 20px;
  }

  .patient-time-results {
    .row {
      margin: 0;
    }

    .monthlyResult {
      border-radius: 5px;
      font-size: 12px;

      div{
        min-height: 90px;
        margin-bottom: 30px;
        background-color: white;
        padding: 10px;
      }

      @media (max-width: 992px) {
        text-align: center;
      }

      & p {
        font-family: 'Poppins-light', sans-serif;
      }

      .subtitle {
        font-family: 'Poppins-semiBold', sans-serif;
        color: #0B801A;
        margin-bottom: 0;
      }
    }

    .cards-spacing {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  .MigraineRecordCalendar{
    margin-bottom: 15px;
  }
  .echarts-for-react{
    margin-top: -53px;
  }
  .stats-widget{
    margin-bottom: 15px;
  }
}