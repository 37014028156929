@import '../../../assets/styles/colors';
@import '../../../assets/styles/measures';

.Medicine{
  background-color: white;
  max-width: $patient-max-width;
  margin: auto;

  .fixed-searchBar{
    width: 100%;
    max-width: $patient-max-width;
    //position: fixed;
    //top: 55px;
    position: sticky;
    z-index: 5;
    padding: 2px 30px;
    background-color: white;
    top: 50px;

    @media( max-width: 480px){
      top: 42px;
    }
  }
  .medicineTopBar {
    h3 { display: none ;}
  }
  .medicine-list{
    background-color: white;
    .MedicineCard {
      margin: 10px auto;
    }
  }
  .sorter {
    margin-top: 4%;
    
    .sort-button {
      background: lighten($app-green1, 40%);
      border: none;
      border-radius: 6px;
      color: $app-green;
      max-width: 100px;
      padding: 6px;
      width: 100%;
      transition: .2s ease-in-out;
      &:focus{
        background: $app-green1;
        color: white;
      }
    }
  }
}
