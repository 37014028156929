@import '../../assets/styles/colors.scss';
#toast-container .toast {
    width: 100% !important;
    max-width: 100% !important;
    right: 15px !important;
}
.DoctorsHome {
        background-color: white;
        height: 100vh;
    div#finder {
        background: linear-gradient(90deg, $light-red -100%, $red 100%);
        padding: 0 20px;
        .finder-container {
            max-width: 80%;
            width: 100%;
            @media(max-width:480px){
                flex-wrap: wrap;
                .SearchBar, .Sorter, .MultiSelect {
                    margin: 5px 0!important;
                    width: 100%;
                }
            }
            .SearchBar {
                width: 100%;
                max-width: 320px;
                border: 2px solid white;
                svg {
                    .b {
                        fill: white;
                    }
                }
        
                input {
                    color: white;
        
                    &::placeholder {
                        color: white;
                    }
                }
            }
            .Sorter {
                border: 2px solid white;
                select {
                    color: white;
                    option {
                        color: black;
                    }
                }
            }
            .MultiSelect {
                max-width: 240px;
                width: 100%;
                .select-container{
                    margin: 0;
                }
                .select-container div {
                    border: 2px solid white;
                    color: white;
                }
                .selected-block{
                    background: $button-primary-color;
                    color: white;
                    margin: 0 7px;
                    padding: 4px 8px;
                }
                .options-wrapper {
                    li {
                        button {
                            background: $app-green;
                            border: none;
                            border-radius: 6px;
                            color: white;
                            padding: 6px;
                        }
                    }
                }
                margin: 0 5px;
            }
        }
        .create-patient {
            background: $button-primary-color;
            color: white;
            border: none;
            border-radius: 6px;
            margin: 5px 10px;
            transition: .2s ease-in-out;
            padding: 6px 32px;
            font-size: calc(1rem + 1vw / 6);
    
            img{
                margin-right: 10px;
            }
        }
    }

    .FormCreator {
        .birth-date-picker {
            border: 2px solid #DD1707;
            border-radius: 9px;
            display: block;

            .react-date-picker__wrapper{ 
                border: none; 
                padding: 10px;
            }
            .birth-date-calendar {
                button {
                    color: black;
                    padding: 5px;
                }
            }
            //Clean Form creator styles
            button, input {
                background: none;
                border: none;
                border-radius: 0;
                display: inline-block;
                padding: 0;
                margin: 0;
                height: auto;
            }

            svg {
                margin-left: 10px;
            }
        }
        label {
            display: block;
        }
        input {
            display: block;
        }
        .active {
            transition: .2s ease-in-out;   
        }
        .muted {
            background-color: rgba(0, 0, 0, 0.2);
            color: black;
            opacity: 0.7;
        }
        .fileContainer p {
            display: none;
        }

    }

    .cards-container{
        padding: 30px 50px;

        .patient-col{
            padding: 10px;
        }
        .patient-card{
            background-color: #f2f0f0;
            border-radius: 15px;
            padding: 20px 15px;
            height: 100%;
            color: black;
            position: relative;
            .profile-info {
                display: flex;
                justify-content: space-between;
                
                img.profile-photo, .no-photo {
                    border-radius: 100%;
                    box-shadow: 0 2px 9px rgba(0,0,0, 0.2);
                    height: 35px;
                    width: 35px;
                }
                img.no-photo {
                    opacity: 0.4;
                }
            }
            .name{
                color: $red;
                font-size: 1.3rem;
                margin-bottom: 34px;
            }
            .patient-info{
                font-family: Poppins-light, sans-serif;
                width: 100%;
                min-height: 15px;
                max-width: 380px;
                margin-bottom: 10px;

                span {
                    font-family: Poppins-semiBold, sans-serif;
                }
            }
            .DotsButton{
                position: absolute;
                right: 15px;
                bottom: 20px;
            }
        }
    }
    //Modal Warning Styles
    .modal-warning{
        section{
            padding: 10px 0;
            h6.modal-warning-message{
                font-size: calc(1rem + 1vw / 6);
                margin: 20px 0 30px;
            }
            div.take-action {
                margin-bottom: 10px;
                button {
                    border: none;
                    border-radius: 3px;
                    box-shadow: 0 3px 9px rgba(0,0,0, 0.1);
                    color: white;
                    margin: 0 4px;
                    padding: 6px 12px;
                }
                .button-action-remove {
                    @extend button;
                    background-color: $red;
                }
                .button-action-cancel {
                    @extend button;
                    background-color: $app-green;
                }
            }
        }
    }
}