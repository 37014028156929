@import '../../../../../assets/styles/colors';

.DateCarousel{
  .week {
    display: flex;
    justify-content: space-between;
  }
  .date-btn{
    background: none;
    border: none;
    color: white;
    display: inline-block;
    width: 30px;
    padding: 6px 0;
    outline: none;
    transition: transform 100ms;

    .date{
      font-family: Poppins-light, sans-serif;
      margin-top: 4px;
    }
    &.active{
      background-color: $light-green;
      border-radius: 15px;

    }
    &:active{
      transform: scale(0.8);
    }
  }
  .carousel-slider.carousel{
    .slide{
      background: none;
    }
    .slider-wrapper.axis-horizontal{
      max-width: 80%;
    }
  }
}
