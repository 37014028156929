@import "../../../../assets/styles/colors";

.EpisodeRow{
  border-bottom: 1px solid rgba($gray,.5);
  position: relative;
  color: black;

  padding-bottom: 15px;
  padding-top: 25px;
  &>div{
    display: inline-block;
    vertical-align: middle;
  }
  .face{
    width: 16%;
    margin-top:0;
    .PainFace{
      position: absolute;
      top: 0;
      .face-wrapper.green{
        top: 13px;
      }
      .face-wrapper.red{
        top: 9px;

      }

    }
  }
  .intensity-label{
    font-weight: bold;
    font-size: .9rem;
    width: 55%;
  }
  .date,.hour{
    font-size: .8rem;
    opacity: .5;
  }
  .date{
    width: 18%;
  }
}