@import '../../assets/styles/colors.scss';

.FormCreator {
    margin: 30px auto;
    width: 80%;
    fieldset {
        label {
            display: block;
            padding: 1px 0 ;
            text-align: left;
        }
        input {
            background: rgba(0,0,0,0);
            border: 2px solid $red;
            border-radius: 10px;
            display: block; 
            margin: 9px auto;
            padding: 7px;
            outline: none;
            width: 100%;
        }
        button {
            background: $button-secondary-color;
            border: none;
            border-radius: 10px;
            color: white;
            cursor: pointer;
            padding: 10px 5%;
        }
    }
}