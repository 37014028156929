@import '../../assets/styles/colors';

.Login {
  background-color: $app-green;
  //Global media queries
  @media(min-width: 980px) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-size: calc(1rem + 1vw / 2);
    padding: 0 21px;
    width: 100%;

    @mixin painFaceIconSize {
      height: 65px;
      width: 65px;
    }
    .desktop-logo {
      margin: auto;
      max-width: 400px;
    }
    .mobile-form-image {
      display: none;
    }

    .desktop-form-image {
      margin-bottom: 30px;
      @include painFaceIconSize;

      .face-wrapper {
        @include painFaceIconSize;
      }

      svg {
        @include painFaceIconSize;
      }
    }
    .initContainer {
      align-items: center;
      display: flex;
      height: 90%;
      width: 100%;

      .logo-wrapper {
        margin: auto;
        width: 90%;
      }
    }
  }
  @media(max-width: 980px) {
    font-size: calc(1rem + 1vw / 2);
    .desktop-logo {
      display: none;
    }
    .mobile-form-image {
      display: block;
    }
    .desktop-form-image {
      display: none;
    }
    .initContainer {
      width: 90%;
    }
  }


  p {
    padding: 5px 15px;
    text-align: center;
  }

  .initPage {
    background-color: $app-green;
    display: flex;
    justify-content: center;
  }

  .initPage .logo {
    width: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    img {
      width: 100%;
    }

    @media(max-width: 980px) {
      width: 30%;
    }
  }

  .initContainer {
    background-color: white;
    align-self: center;
    border-radius: 20px;
  }

  .logo-wrapper {
    @media(max-width: 980px) {
      margin-top: 20px;
    }
  }

  //Form styles
  .login-form {
    margin: 40px auto;
    max-width: 600px;
    width: 70%;

    @media(max-width: 980px) {
      margin: 10px auto;
      width: 90%;
    }

    input {
      background: lighten($app-green1, 40%);
      border-radius: 10px;
      border: none;
      font-family: Poppins-semiBold;
      margin: 20px 0;
      color: $app-green;

      &:focus {
        background-color: white;
      }

      &::placeholder {
        color: $app-green;
      }
    }

    .Button {
      display: block;
      width: 100%;
      margin-bottom: 20px;
    }
  }

  @keyframes loginLogoUp {
    0% {
      margin-top: calc(50vh - 120px);
    }
    100% {
      margin-top: 100px;
    }
  }

  .version {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #5ca065;
    font-size: 14px;

    @media(max-width: 980px) {
      position: relative;
    }
  }

  .forgot-password {
    text-align: center;
    font-size: 13px;

    a {
      color: gray;
    }
  }

  .terms {
    text-align: left;
    font-size: 12px;
    background: #cce8d0;
    color: $app-green;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;

    @media(max-width: 980px) {
      font-size: 10px;
    }

    button {
      padding: 0;
      color: $app-green;

      span {
        display: inline-block;
        margin-right: 5px;
      }
    }

    p {
      text-align: left;
    }

    .accept-button {
      background: none;
      border: none;

      &:focus, &:checked, &:active, {
        border: none;
        outline: none;
      }
    }

    li {
      margin-bottom: 0;
    }

    a {
      text-decoration: underline;
    }

    ul {
      margin-bottom: 0;
    }

    p {
      margin-bottom: 0;
      padding: 0;
    }

    margin-bottom: 20px;

  }

  .error {
    font-size: 20px;
    color: $red;
  }
}

