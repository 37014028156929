@import "colors";

html,body{
  font-family: Poppins-medium, sans-serif;
  font-size: 14px;
}

b, strong{
  font-family: Poppins-semiBold, sans-serif;
  font-weight: 600;
}
a:hover{
  text-decoration: none;
}

a:hover {
  color: inherit;
}
a{
  color: inherit;
}



.light{
  font-family: Poppins-light, sans-serif;
  font-weight: lighter;
}

.App{
  color: $font-black;
}

.Input{
  background-color: $opacity-white;
  border-radius: 10px;
  border: none;
  margin: 20px 0;
  padding: 10px;
  color: white;
  &::placeholder{
    color: $opacity-white;
  }
}
.Button{
  padding: 8px;
  border: none;
  background-color: $app-black;
  color: white;
  border-radius: 10px;
  font-size: 16px;
  transition: transform 80ms;
  &:focus{
    outline: none;
  }
  &:active{
    transform: scale(0.95);
  }
}

.circled-back{
  position: relative;
  &::after{
    content:' ';
    position: absolute;
    border-radius: 100%;
    left: 18%;
    top: 16%;
    width: 85%;
    height: 85%;
    background-color: $light-red;
    z-index: -1;
  }
}

.scrollable-content{
  overflow-y: auto;
  padding: 35px 30px 30px;
  min-height: 100vh;
  background-color: white;
  &.medicine{
    padding-top: 150px;
  }
}

.gray-background{
  background-color: $light-gray;
  z-index: -3;
}
