@import 'src/assets/styles/colors';

.ConfigurationModal {
  padding-bottom: 10px;

  .modal-container {
    padding-bottom: 15px;
  }

  .chrome-picker {
    margin: auto;
  }

  section{
    padding: 20px;
  }

  .fileContainer{
    p{
      display: none;
    }
  }

  .Button{
    background-color: $app-green;
  }

  .current-logo{
    margin-top: 12px;
    border-radius: 5px;
    width: 140px;
    height: 140px;
    background-color: white;
    position: relative;
    img{
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      max-width: 100%;
    }
  }

}